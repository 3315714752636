import React, { useEffect, useState } from "react";
import './static/Whatsapp.css'
import axios from "axios";
import NuevaColeccion from "./Templates/NuevaColeccion";
import Descuentos from "./Templates/Descuentos";
import Modal from "./Templates/Modal";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const Whatsapp = ({apiUrlState}) => {
  const Mensaje = "🚨 *NUEVA COLECCIÓN* 🚨 ¡Ya tenemos *{{1}}* Tienen un valor de *${{2}} pesos al por mayor* y *${{3}} pesos por unidad.* Recuerda que también tenemos disponibles: - Gorras 🧢 - Camisetas para hombre 👕 - Camisetas para mujer 👚 - Polos 👕 - Hoodies para Hombre y Mujer 🧥 - Joggers para Hombre y Mujer 👖 - Pantalonetas 🏝️ Para ver todos nuestros productos solo debes hacer CLICK en el botón ir a Mayoristas o en el siguiente link: http://www.dkda.com.co/Mayoristas 👈 P.D: Si no quieres seguir recibiendo noticias sobre nuevos productos o promociones, solo escríbenos para eliminarte de nuestros contactos."
  const [vars, setVars] = useState({
    Plantilla: "notificacion_de_nueva_coleccion",
    Categoria: "Nuevas Camisetas para Hombre 👕",
    PMayorista: "",
    PMinorista: "",
    TipoEnvio:"Test",
    TipoCliente: "Mayorista"
  });

  const [varsDesc, setVarsDesc] = useState({
    Plantilla: "temporada_descuentos",
    InicioDesc: "",
    FinDesc: "",
    Descuento:"",
    TipoCliente: "Mayorista"
  });
  const [archivos, setArchivos]= useState(null);
  const [dataFile, setdataFile]= useState(null);
  const [countClients, setcountClients]= useState(null);

  const { TipoEnvio, Plantilla, Categoria, PMayorista, PMinorista } = vars;
  const { InicioDesc, FinDesc, Descuento } = varsDesc;
  const uploadFile =(e)=>{
    setArchivos(e)
    //TODO esto captura el archivo!
    /*const [ file ] = e.target.files;
    setdataFile ({
      fileRaw:file,
      fileName:file.name
    })*/
    
  }

  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (token) {
      let urlcount = apiUrlState+"/Api/whatsapp/countClients"
      let dataCount = {
        type: TipoEnvio
      }

    }else{
      history.push("/login")
    }
    //console.log(reports)
  },[token]);

  const optionsTemplate = [
  {
    label: "Nueva Colección",
    value: "notificacion_de_nueva_coleccion",
  },
  {
    label: "Descuentos",
    value: "temporada_descuentos",
  }
];

const optionsCategoria= [
  {
    label: "Camiseta Hombre",
    value: "Nuevas Camisetas para Hombre 👕",
  },
  {
    label: "Camisetas Mujer",
    value: "Nuevas Camiseta para Mujer 👚!",
  },
  {
    label: "Gorras",
    value: "Nuevas Gorras 🧢 !",
  },
  {
    label: "Polos",
    value: "Nuevos Polos 👕 !",
  },
  {
    label: "Hoodies Hombre",
    value: "Nuevos Hoodies para Hombre 🧥!",
  },
  {
    label: "Hoodies Mujer",
    value: "Nuevos Hoodies para Mujer 🧥!",
  },
  {
    label: "Jogger Hombre",
    value: "Nuevos Jogger para Hombre 👖!",
  },
  {
    label: "Jogger Mujer",
    value: "Nuevos Jogger para Mujer 👖!",
  },

  {
    label: "Pantalonetas",
    value: "Nuevas Pantalonetas 🏝️!",
  }
];

  const insertarArchivo = async() => {
    console.log("Entra")
    const f = new FormData();
    
    f.append("myFile", archivos[0])
    //f.append('myFile', dataFile.fileRaw, dataFile.fileName);
    console.log("F: ", f)
    await axios.post(apiUrlState+"/Api/whatsapp/catalog",f)    /*,{headers: {'Content-Type': 'multipart/form-data'}}*/
    .then(response =>{
      console.log(response.data)
    }).catch(error => {
      console.log(error)
    })
  }

  const modChange  = async (e) => {
    console.log("[e.target.name]", e.target.name)
    console.log("[e.target.value]", e.target.value)
    let urlcount = apiUrlState+"/Api/whatsapp/countClients"
      let dataCount = {
        type: e.target.value
      }
      await axios
        .post(urlcount, dataCount)
        .then((res) => {
          const { data } = res;
          console.log("data: ", data.count)
          setcountClients(data.count)
        })
        .catch((error) => {
          console.error(error);
          //setMensaje("email u password incorrecta");
          setTimeout(() => {
            //setMensaje("");
          }, 1500);
        });
    

  };

  const HandleChange = (e) => {
    console.log("[e.target.name]", e.target.name)
    console.log("[e.target.value]", e.target.value)
    /*if(Plantilla === "notificacion_de_nueva_coleccion"){
      setVars({ ...vars, [e.target.name]: e.target.value });
    }else{
      setVarsDesc({ ...varsDesc, [e.target.name]: e.target.value });
    }*/
    setVars({ ...vars, [e.target.name]: e.target.value });
    setVarsDesc({ ...varsDesc, [e.target.name]: e.target.value });
    

  };

  const onSubmit = async (e) => {
    
    console.log("ENTRA CLICK", e.Categoria, Categoria,e.Plantilla, Plantilla, countClients )
    e.preventDefault();
    await insertarArchivo()
    let url = apiUrlState+"/Api/whatsapp/sendmessage"

      let data = {
        Plantilla,
        Categoria,
        PMayorista, 
        PMinorista,
        TipoEnvio
      };
      if(Plantilla === "temporada_descuentos"){
        data={
          Plantilla,
          InicioDesc,
          FinDesc,
          Descuento,
          TipoEnvio
        }
        url = apiUrlState+"/Api/whatsapp/sendmessageDesc"
      }
      //setLoading(true);
      
      
      
      await swal({
        title: `Esta seguro de enviar la campaña ${Plantilla} a ${countClients} numeros de Whatsapp ?`,
        text: "Esta accion no se podra detener despues de ser enviada",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        axios
        .post(url, data)
        .then((res) => {
          const { data } = res;
          //setMensaje(data.mensaje);
          setTimeout(() => {
            //setMensaje("");
            
            localStorage.setItem("token", data?.token);
            //history.push('/Admin/Indicadores')
            //navigate(`/welcome`);
          }, 1500);
        })
        .catch((error) => {
          console.error(error);
          //setMensaje("email u password incorrecta");
          swal("Error al enviar campaña", {
            icon: "error",
          });
          setTimeout(() => {
            //setMensaje("");
          }, 1500);
        });
  
          swal("Campaña enviada correctamente!", {
            icon: "success",
          });
        } else {
          swal("Se cancelo el envio de la campaña");
        }
      });

      
      //setInputs({ email: "", password: "" });
      //setLoading(false);

  };

  return (
    <div className="struct">
      <form onSubmit={(e) => onSubmit(e)} style={{width: "50vw"}}>
        <div class="form-group">
          <label for="exampleFormControlSelect1" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}>Plantillas</label>
          <select 
              className="form-control FuenteBebas" 
              id="exampleFormControlSelect1"
              onChange={(e) => HandleChange(e)}
              name = "Plantilla">
                <option value="--">--</option>
              {optionsTemplate.map((option, index) => (
                  <option 
                  key={index+"P"}
                  value={option.value}
                  
                  >{option.label}</option>
                ))}
          </select>
        </div>
        <div class="form-group">
          <label for="TipoEnvio" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}>Tipo de envio</label>
          <select 
              className="form-control FuenteBebas" 
              id="TipoEnvio"
              onChange={(e) => modChange(e)}
              name = "TipoEnvio">
                  <option value="--">--</option>
                  <option value="Test">Test</option>
                  <option value="Producción">Producción</option>
          </select>
        </div>
        <div class="form-group">
          <label for="TipoCliente" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}>Tipo de Cliente</label>
          <select 
              className="form-control FuenteBebas" 
              id="TipoCliente"
              onChange={(e) => HandleChange(e)}
              name = "TipoCliente">
                  <option value="--">--</option>
                  <option value="Minorista">Minorista</option>
                  <option value="Mayorista">Mayorista</option>
                  <option value="Todos">Todos</option>
          </select>
        </div>
        { vars.Plantilla === "notificacion_de_nueva_coleccion" ? <>
        <div class="form-group">
          <label for="exampleFormControlSelect3" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}>Categoria</label>
          <select className="form-control FuenteBebas" 
                  id="exampleFormControlSelect3"
                  onChange={(e) => HandleChange(e)}
                  name = "Categoria">
                    <option value="--">--</option>
          {optionsCategoria.map((option, index) => (
                  <option 
                  key={index+"C"}
                  value={option.value}
                  
                  >{option.label}</option>
                ))}
          </select>
        </div>
        <div class="form-group">
          <label htmlFor="PMayorista" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Precio Mayorista</label>
          <input
            onChange={(e) => HandleChange(e)}
            name = "PMayorista"
            value={PMayorista}
            type="text"
            class="form-control FuenteBebas"
            id="PMayorista"
            placeholder="28.900"
          />
        </div>
        <div class="form-group">
          <label htmlFor="PMinorista" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Precio Minorista</label>
          <input
            onChange={(e) => HandleChange(e)}
            value={PMinorista}
            name="PMinorista"
            type="text"
            class="form-control FuenteBebas"
            id="PMinorista"
            placeholder="28.900"
          />
        </div>
        </>
        :
        <>
          <div class="form-group">
          <label htmlFor="InicioDesc" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Inicio Descuentos</label>
          <input
            onChange={(e) => HandleChange(e)}
            name = "InicioDesc"
            value={varsDesc.InicioDesc}
            type="text"
            class="form-control FuenteBebas"
            id="InicioDesc"
            placeholder="Eje: Desde Hoy"
          />
        </div>
        <div class="form-group">
          <label htmlFor="FinDesc" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Fin Descuentos</label>
          <input
            onChange={(e) => HandleChange(e)}
            value={vars.FinDesc}
            name="FinDesc"
            type="text"
            class="form-control FuenteBebas"
            id="FinDesc"
            placeholder="Eje: Hasta el 28 Noviembre"
          />
        </div>
        <div class="form-group">
          <label htmlFor="Descuento" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Descuento</label>
          <input
            onChange={(e) => HandleChange(e)}
            value={varsDesc.Descuento}
            name="Descuento"
            type="text"
            class="form-control FuenteBebas"
            id="Descuento"
            placeholder="Eje: 50% OFF"
          />
        </div>
        </>
        
        }
        <div class="form-group">
          <label for="exampleFormControlInput1" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}
                    >Subir catalogo</label>
          <input
            className="form-control"
            id="formFile"
            type="file"
            name="files"
            onChange={(e) =>uploadFile(e.target.files)}
          />
        </div>
        <div className="form-group">
          <label for="exampleFormControlTextarea1" className="text-center FuenteBebas"
                    style={{ fontSize: "20px" }}>Vista previa</label>
          {
            vars.Plantilla === "notificacion_de_nueva_coleccion" ?  
            <NuevaColeccion 
            vars={vars}
          />
           :
           <Descuentos
           varsDesc = {varsDesc}
           />
          }
          
          
        </div>
        
        <button className="botonIngresar text-center FuenteBebas"
                style={{ cursor: "pointer" }}>
          {false ? "Cargando..." : "Enviar"}
        </button>
        
                
      </form>

    </div>
  );
};

export default Whatsapp;
