import React, { useEffect, useState } from "react";
//import epayco from 'epayco-sdk-js';

function EpaycoButton() {
  // Configuración del botón de Epayco
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');

  function procesarRespuestaEPayco(response) {
    if (response.status === 'Aprobada') {
      // mostrar mensaje de confirmación en tu sitio web    
      alert('El pago se ha realizado correctamente. ¡Gracias por tu compra!');
    } else {
      // mostrar mensaje de error en tu sitio web    
      alert('Lo sentimos, el pago no se ha podido procesar en este momento. Por favor, intenta de nuevo más tarde.');
    }
  }
  const data = {
    key: '0026d7220b852a0f67c6ed61d2e8c9fd',
    amount: '10000',
    name: 'Producto de prueba',
    description: 'Descripción del producto',
    image: 'https://picsum.photos/200', // URL de la imagen del producto
    currency: 'COP',
    country: 'CO',
    factura: 44444,
    test: true,
    external: true,
    //response: false,
    //response: 'http://localhost', // URL de la página de respuesta
    confirmation: 'http://localhost', // URL de la página de confirmación
    acepted: "https://www.dkda.com.co/Pedido/Confirmacion",
    rejected: "https://www.dkda.com.co/Pedido/Rechazado",
    pending: "https://www.dkda.com.co/Pedido/Pendiente",
    // onapproved: () => setPaymentSuccess(true),
    // onrejected: () => setPaymentError(true),
    // response: procesarRespuestaEPayco,
    // confirmation: (confirmation) => {
    //   console.log(confirmation);
    // },
    close: () => {
      console.log('Modal cerrado');
    }
  };

  // Función que se ejecuta al hacer clic en el botón de Epayco
  const handleEpaycoClick = () => {
    // Carga el script de Epayco y muestra el formulario de pago
    const handler = window.ePayco.checkout.configure({
      key: data.key,
      test: data.test,
    });
    // Agregar el evento message al objeto window para escuchar el mensaje de cierre del modal
    window.addEventListener('message', function(event) {
      console.log("ENTRA: ", event.data)
      //console.log("ENTRA Error: ", JSON.stringify(event))
      if(event.data.event === 'onCloseModal') {
        alert('el modal se ha cerrado.');
      }
      if(event.error) {
        alert('Error en el pago, vuelve a intentarlo.');
      }
      // Verificar que el mensaje provenga del dominio de ePayco
      if (event.origin === 'https://checkout.epayco.co') {
        // Verificar que el mensaje sea de tipo "close" y que la transacción se haya completado
        if (event.data === 'close' || event.data.status === 'Aceptada') {
          // Mostrar un mensaje de confirmación de pago finalizado o modal cerrado
          alert('El pago ha sido confirmado o el modal se ha cerrado.');
        }
      }
    });

    // Agregar el evento error al objeto window para escuchar el mensaje de error en el proceso de pago
    window.addEventListener('r', function(event) {
      // Verificar que el mensaje provenga del dominio de ePayco
      console.log("ENTRA ERRORRR-------------: ", event)
      if (event.origin === 'https://checkout.epayco.co') {
        // Mostrar un mensaje de error en el proceso de pago
        alert('Ha ocurrido un error en el proceso de pago.');
      }
    });
    handler.open(data,(response) => {
      if (response.status === 'Aprobada') {
        // la transacción fue aprobada, hacer algo aquí
      } else {
        // la transacción no fue aprobada, hacer algo aquí
      }
    });

  };

  const handlePayment = () => {
    // Configuración del botón de pago
    const data = {
      key: '0026d7220b852a0f67c6ed61d2e8c9fd',
      amount: '10000',
      name: 'Producto de prueba',
      description: 'Descripción del producto',
      image: 'https://picsum.photos/200', // URL de la imagen del producto
      currency: 'COP',
      country: 'CO',
      test: false,
      external: false,
      response: 'http://localhost', // URL de la página de respuesta
      confirmation: 'http://localhost', // URL de la página de confirmación
      onapproved: () => setPaymentSuccess(true),
      onrejected: () => setPaymentError(true)
    };


  
    // Creación del botón de pago con Epayco
    const button = new window.ePayco.Button(data);
    button.onClick();
  };

  const handlePayment2 = () => {

  };

  // useEffect(() => {
  //   if (paymentSuccess) {
  //     console.log("todo ok ")
  //   }
  //   console.log("paymentSuccess", paymentSuccess)
  //   console.log("paymentError", paymentError)
  //   if (paymentError) {
  //     console.log("Cierra ")
  //   }
  // }, [paymentSuccess, paymentError])

  useEffect(() => {
    console.log("paymentStatus: ", paymentStatus)
  }, [paymentStatus])

  useEffect(() => {
    const btnFinalizarCompra = document.getElementById('btn-finalizar-compra');
    if (btnFinalizarCompra) {
      btnFinalizarCompra.addEventListener('click', handleFinalizarCompra);
    }

    // limpiar evento de escucha cuando se desmonta el componente
    return () => {
      if (btnFinalizarCompra) {
        btnFinalizarCompra.removeEventListener('click', handleFinalizarCompra);
      }
    }
  }, []);

  function handleFinalizarCompra() {
    console.log("Pago fue realizado")
  }

  return (
    <>
      <button  onClick={handleEpaycoClick}><img src='https://multimedia.epayco.co/epayco-landing/btns/Boton-epayco-color2.png'/></button>
      <div>
      <button onClick={handlePayment}>Pagar</button>
      {paymentSuccess && <p>Pago exitoso</p>}
      {paymentError && <p>Error en el pago</p>}
      </div>
      <div>
      <h1>Checkout</h1>
      <button onClick={handlePayment2}>Pagar2</button>
      {paymentStatus === 'approved' && <p>Pago realizado exitosamente.</p>}
      {paymentStatus === 'rejected' && <p>Error al procesar el pago.</p>}
    </div>
    </>

  );
}

export default EpaycoButton;