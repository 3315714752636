//Zona de importacion de componentes externos.
import React from "react";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

import Producto from "./Producto";
import "./static/css/Mayoristas/Mayoristas.css";

const ListaProductos = ({
  ViewDetalle,
  headerState,
  modificarHeaderState,
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  carrito,
  modificarCarritoState,
  cliente,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  categoriasHombreState,
  modificarCategoriasHombreState,
  categoriasMujerState,
  modificarCategoriasMujerState,
  CatalogoState,
  actualizarSessionStorage,
  estadoBar,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
}) => {
  return (
    <SlideDown className={"dropdown-slidedown"} closed={!estadoBar}>
      <div>
        {productosState[1].length > 0 ? (
          <div id="filaMayorita">
            {productosState[1].map((producto, index) =>
              index === 0 ? (
                <Producto
                  key={producto["Codigo"]}
                  cliente={cliente}
                  index={index}
                  producto={producto}
                  carrito={carrito}
                  modificarCarritoState={modificarCarritoState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  actualizarSessionStorage={actualizarSessionStorage}
                  ViewDetalle={ViewDetalle}
                  tallasState={tallasState}
                  modificarTallasState={modificarTallasState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}  
                />
              ) : (
                <div key={producto["Codigo"]} className="productosDespliegue">
                  <Producto
                    key={producto["Codigo"]}
                    cliente={cliente}
                    index={index}
                    producto={producto}
                    carrito={carrito}
                    modificarCarritoState={modificarCarritoState}
                    headerState={headerState}
                    modificarHeaderState={modificarHeaderState}
                    errorState={errorState}
                    modificarErrorState={modificarErrorState}
                    spinnerState={spinnerState}
                    modificarSpinnerState={modificarSpinnerState}
                    apiUrlState={apiUrlState}
                    modificarApiUrlState={modificarApiUrlState}
                    recursosUrlState={recursosUrlState}
                    modificarRecursosUrlState={modificarRecursosUrlState}
                    actualizarSessionStorage={actualizarSessionStorage}
                    ViewDetalle={ViewDetalle}
                    tallasState={tallasState}
                    modificarTallasState={modificarTallasState}
                    vendedor={vendedor}
                    modificarVendedorState={modificarVendedorState}
                    Bodega={Bodega}
                    setBodega={setBodega}    
                  />
                </div>
              )
            )}
          </div>
        ) : (
          <div>
            {estadoBar && !spinnerState["Desplegar"] ? (
              <div>
                <div id="noHayWebM">
                  <img
                    src={recursosUrlState + "/img/General/noHayWeb.webp"}
                    style={{ width: "100%" }}
                    alt="noHayWeb"
                    className="ImgNoHayWebM"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        recursosUrlState + "/img/General/noHayWeb.jpg";
                    }}
                  />
                </div>
                {/* <div id="noHayMovilM">
                    <img
                      src={recursosUrlState + "/img/General/noHayMovil.webp"}
                      alt="noHayMovil"
                      className="ImgNoHayMovilM"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          recursosUrlState + "/img/General/noHayMovil.jpg";
                      }}
                    />
                  </div> */}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </SlideDown>
  );
};

export default ListaProductos;
