//Zona de importacion de componentes externos.
import React, { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import FormRegistro from "./FormRegistro";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";
//import { Checkbox } from '@material-ui/core;

const Registro = ({
  headerState,
  modificarHeaderState,
  pedidoState,
  modificarPedidoState,
  // clienteState,
  // modificarClienteState,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  actualizarSessionStorage,
  actualizar,
  setActualizar,
  vendedor,
}) => {
  const { clienteState, modificarClienteState } = useGlobalContext();

  //const [direccionState, modificarDireccionState] = useState({via: "",Nro: "",Letra: "", Sector: "", Cruce: "", NroPlaca: "", LetraPlaca: "", NroPlaca2: "" });
  const path = useLocation();
  //Obtiene todos los datos del clienteState y rellena los inputs del formulario
  const llenarInfo = async (usuario, actualizar, datosApi) => {
    var usuarioCarga = {
      Registro: false,
      Accion: "Activo",
      Sesion: actualizar ? true : usuario["Sesion"],
      TipoDocumento: usuario["TipoDocumento"],
      NumeroDocumento: usuario["NumeroDocumento"],
      Nombres:
        usuario["Nombres"] !== ""
          ? capitalizarPalabras(usuario["Nombres"])
          : "",
      Apellidos:
        usuario["Apellidos"] !== ""
          ? capitalizarPalabras(usuario["Apellidos"])
          : "",
      Correo: usuario["Correo"],
      Genero: usuario["Genero"],
      Celular: usuario["Celular"],
      // FechaNacimiento: datosApi
      //   ? convertDateFormat(usuario["FechaNacimiento"])
      //   : usuario["FechaNacimiento"],
      // Universidad: usuario["Universidad"],
      Pais: datosApi ? usuario.Ciudad.Departamento.Pais._id : usuario["Pais"],
      Ciudad: datosApi ? usuario.Ciudad._id : usuario["Ciudad"],
      Departamento: datosApi
        ? usuario.Ciudad.Departamento._id
        : usuario["Departamento"],
      Direccion: usuario["Direccion"],
      Barrio: usuario["Barrio"],
    };
    if (usuarioCarga["TipoDocumento"] !== "") {
      document.getElementById("tipoD_input").value =
        usuarioCarga["TipoDocumento"];
      document.getElementById("tipoD_input").classList.add("correcto");
      document.getElementById("tipoD_input").setAttribute("title", "");
    } else {
      document.getElementById("tipoD_input").value = "1"
    }
    if (usuarioCarga["NumeroDocumento"] !== "") {
      document.getElementById("numeroD_input").value =
        usuarioCarga["NumeroDocumento"];
      document.getElementById("numeroD_input").classList.add("correcto");
      document.getElementById("numeroD_input").setAttribute("title", "");
    }
    if (usuarioCarga["Nombres"] !== "") {
      document.getElementById("nombres_input").value = usuarioCarga["Nombres"];
      document.getElementById("nombres_input").classList.add("correcto");
      document.getElementById("nombres_input").setAttribute("title", "");
    }
    if (usuarioCarga["Apellidos"] !== "") {
      document.getElementById("apellidos_input").value =
        usuarioCarga["Apellidos"];
      document.getElementById("apellidos_input").classList.add("correcto");
      document.getElementById("apellidos_input").setAttribute("title", "");
    }else {
      document.getElementById("apellidos_input").value = ""
    }
    if (usuarioCarga["Correo"] !== "") {
      document.getElementById("correo_input").value = usuarioCarga["Correo"];
      document.getElementById("correo_input").classList.add("correcto");
      document.getElementById("correo_input").setAttribute("title", "");
    }
    if (usuarioCarga["Genero"] !== "") {
      document.getElementById("genero_input").value = usuarioCarga["Genero"];
      document.getElementById("genero_input").classList.add("correcto");
      document.getElementById("genero_input").setAttribute("title", "");
    }
    if (usuarioCarga["Celular"] !== "") {
      document.getElementById("celular_input").value = usuarioCarga["Celular"];
      document.getElementById("celular_input").classList.add("correcto");
      document.getElementById("celular_input").setAttribute("title", "");
    }
    // if (usuarioCarga["FechaNacimiento"] !== "") {
    //   document.getElementById("fechaNacimiento_input").value =
    //     cambioFormatoFecha(usuarioCarga["FechaNacimiento"]);
    //   document
    //     .getElementById("fechaNacimiento_input")
    //     .classList.add("correctoFecha");
    //   document
    //     .getElementById("fechaNacimiento_input")
    //     .setAttribute("title", "");
    // }
    // if (path.pathname === "/Concursonacionaldediseno" && usuarioCarga["Universidad"] !== "") {
    //   // Solo para el concurso
    //   document.getElementById("universidad_input").value = usuarioCarga["Universidad"];
    //   document.getElementById("universidad_input").classList.add("correcto");
    //   document.getElementById("universidad_input").setAttribute("title", "");
    // }
    if (usuarioCarga["Direccion"] !== "") {
      document.getElementById("direccion_input").value =
        usuarioCarga["Direccion"];
      document.getElementById("direccion_input").classList.add("correcto");
      document.getElementById("direccion_input").setAttribute("title", "");
    }
    if (usuarioCarga["Barrio"] !== "") {
      document.getElementById("barrio_input").value = usuarioCarga["Barrio"];
      document.getElementById("barrio_input").classList.add("correcto");
      document.getElementById("barrio_input").setAttribute("title", "");
    } else {
      document.getElementById("barrio_input").value = "";
      document.getElementById("barrio_input").classList.add("error");
      document.getElementById("barrio_input").setAttribute("title", "");
    }
    if (usuarioCarga["Pais"] !== "") {
      document.getElementById("pais_input").value = usuarioCarga["Pais"];
      document.getElementById("pais_input").classList.add("correcto");
      document.getElementById("pais_input").setAttribute("title", "");
      await insertarDepartamentos(
        usuarioCarga["Pais"],
        async function (respuesta) {
          if (usuarioCarga["Departamento"] !== "") {
            document.getElementById("departamento_input").value =
              usuarioCarga["Departamento"];
            document
              .getElementById("departamento_input")
              .classList.add("correcto");
            document
              .getElementById("departamento_input")
              .setAttribute("title", "");
            await insertarCiudades(usuarioCarga["Departamento"], function () {
              if (usuarioCarga["Ciudad"] !== "") {
                document.getElementById("ciudad_input").value =
                  usuarioCarga["Ciudad"];
                document
                  .getElementById("ciudad_input")
                  .classList.add("correcto");
                document
                  .getElementById("ciudad_input")
                  .setAttribute("title", "");
              }
            });
          }
        }
      );
    }

    actualizarSessionStorage("Cliente", JSON.stringify(usuarioCarga));
    if (actualizar) modificarClienteState(usuarioCarga);
  };

  //Retorna el string val ingresada con la primera letra mayuscula.
  const capitalizarPalabras = (val) => {
    var minuscula = val.toLowerCase();
    minuscula = minuscula.split(" ");
    var result = "";
    for (var i = 0; i < minuscula.length; i++) {
      result +=
        minuscula[i][0].toUpperCase() +
        minuscula[i].slice(1) +
        (i + 1 < minuscula.length ? " " : "");
    }
    return result;
  };

  //Retorna la fecha ingresada cambiandole el formato.
  const cambioFormatoFecha = (fecha) => {
    var partido = fecha.split("-");
    return partido[2] + "-" + partido[0] + "-" + partido[1];
  };

  //Inserta los departamentos en el select de departamentos
  const insertarDepartamentos = async (CodigoPais, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await ConsultarDepartamentos(CodigoPais, function (JsonDepto) {
      var departamentos = document.getElementById("departamento_input");
      for (let i = departamentos.options.length; i >= 0; i--) {
        departamentos.remove(i);
      }
      var option = document.createElement("option");
      option.value = "";
      option.disabled = true;
      option.hidden = true;
      option.text = "Seleccione su departamento";
      departamentos.appendChild(option);
      option.disabled = false;
      option.hidden = false;
      for (var index = 0; index < JsonDepto.length; index++) {
        var optionDep = document.createElement("option");
        optionDep.value = JsonDepto[index]["_id"];
        optionDep.text = capitalizarPalabras(JsonDepto[index]["Nombre"]);
        departamentos.appendChild(optionDep);
      }
      modificarSpinnerState({ Desplegar: false, Texto: spinnerState["texto"] });
      callback({ Estado: true });
    });
  };
  //Inserta los ciudades en el select de ciudad
  const insertarCiudades = async (CodigoDepartamento, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await ConsultarCiudades(CodigoDepartamento, function (JsonCiudad) {
      var ciudades = document.getElementById("ciudad_input");
      for (let i = ciudades.options.length; i >= 0; i--) {
        ciudades.remove(i);
      }
      var option = document.createElement("option");
      option.value = "";
      option.disabled = true;
      option.hidden = true;
      option.selected = true;
      option.text = "Seleccione su ciudad";
      ciudades.appendChild(option);
      option.disabled = false;
      option.hidden = false;
      option.selected = false;
      for (var index = 0; index < JsonCiudad.length; index++) {
        var optionDep = document.createElement("option");
        optionDep.value = JsonCiudad[index]["_id"];
        optionDep.text = capitalizarPalabras(JsonCiudad[index]["Nombre"]);
        ciudades.appendChild(optionDep);
      }
      if (spinnerState["Desplegar"]) {
        modificarSpinnerState({
          Desplegar: !spinnerState["Desplegar"],
          Texto: "Cargando productos...",
        });
      }
      callback({ Estado: true });
    });
  };

  //Se realiza una consulta de todos los departamentos segun un pais.
  const ConsultarDepartamentos = async (Pais, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await axios
      .post(apiUrlState + "/Api/Departamento/ObtenerDepartamentos", {
        data: {
          Pais: Pais,
        },
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(response.data.data);
        } else {
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al consultar Departamentos",
            });
          }
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al consultar Departamentos",
          });
        }
      });
  };

  //Se realiza una consulta de todas las ciudades segun un departamento.
  const ConsultarCiudades = async (Departamento, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await axios
      .post(apiUrlState + "/Api/Ciudad/ObtenerCiudades", {
        data: {
          Departamento: Departamento,
        },
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(response.data.data);
        } else {
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "No hay ciudades para este Departamento",
            });
          }
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  //Retorna true si el string ingresado contiene algun numero.
  const todoNumeros = (texto) => {
    var numeros = "0123456789";
    for (var i = 0; i < texto.length; i++) {
      if (numeros.indexOf(texto.charAt(i), 0) === -1) {
        return true;
      }
    }
    return false;
  };

  //Retorna true si el string ingresado contiene algun caracter especial.
  const tiene_caracter_especial = (texto) => {
    var letras = "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ ";
    texto = texto.toLowerCase();
    for (var i = 0; i < texto.length; i++) {
      if (letras.indexOf(texto.charAt(i), 0) === -1) {
        return true;
      }
    }
    return false;
  };

  //Consulta a un usuario en la Bd segun su numero de documento.
  const ConsultarUsuario = async (Identificacion, callback) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Investigando tus antecedentes...",
      });
    }
    await axios
      .get(apiUrlState + "/Api/Usuario/GetInfoUsuarioMger/" + Identificacion)
      .then((response) => {
        // console.log(response)
        if (response.data.existe) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(response.data.data);
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(null);
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  //Convierte la fecha ingresada a formato mm/aaaa/dd
  function convertDateFormat(string) {
    var primerSplit = string.split("T");
    var info = primerSplit[0].split("-");
    return info[1] + "-" + info[2] + "-" + info[0];
  }
  //Funcion principal:
  //Detecta que tipo de input se esta rellenando y valida la informacion en este.
  //-Corrige escritura automaticamente y bloquea la escritura incorrecta.
  //- Insertar el logo de correcto o incorrecto.
  //Actualiza el state de Cliente y tambien el sesionStorage.
  const validacion = async (e) => {
    var elemento = document.getElementById(e.target.id);
    if (e.target.value === "") {
      elemento.setAttribute("title", "Este campo no puede estar vacío");
      elemento.classList.add("error");
      elemento.classList.remove("correcto");
      actualizarSessionStorage(
        "Cliente",
        JSON.stringify({
          ...clienteState,
          [e.target.name]: "",
        })
      );
      modificarClienteState({
        ...clienteState,
        [e.target.name]: "",
      });
    } else {
      if (e.target.name === "NumeroDocumento" || e.target.name === "Celular") {
        if (todoNumeros(e.target.value)) {
          elemento.setAttribute(
            "title",
            "Este campo no puede contener letras o caracteres especiales, solo numeros"
          );
          elemento.classList.add("error");
          e.target.value = "";
          modificarErrorState({
            Error: true,
            Mensaje:
              "Este campo no puede contener letras o caracteres especiales, solo numeros",
          });
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else if (
          e.target.name === "NumeroDocumento" &&
          (e.target.value.length < 6 || e.target.value.length > 13)
        ) {
          elemento.setAttribute(
            "title",
            "Este campo no puede contener menos de 6 digitos O mas de 13 digitos"
          );
          elemento.classList.add("error");
          if (e.target.value.length === 6 || e.target.value.length > 13) {
            modificarErrorState({
              Error: true,
              Mensaje:
                "Este campo no puede contener menos de 6 digitos O mas de 13 digitos",
            });
          }
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else if (e.target.name === "Celular" && e.target.value.length < 10) {
          elemento.setAttribute(
            "title",
            "Este campo no puede contener menos de 10 digitos"
          );
          elemento.classList.add("error");
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else {
          elemento.setAttribute("title", "");
          elemento.classList.remove("error");
          elemento.classList.add("correcto");
          if (e.target.name === "NumeroDocumento") {
            if (!clienteState["Registro"]) {
              var antes = e.target.value;
              setTimeout(async function () {
                if (antes === e.target.value) {
                  await ConsultarUsuario(e.target.value, function (usuario) {
                    if (usuario !== null) llenarInfo(usuario, true, true);
                    else {
                      actualizarSessionStorage(
                        "Cliente",
                        JSON.stringify({
                          ...clienteState,
                          [e.target.name]: e.target.value,
                        })
                      );
                      modificarClienteState({
                        ...clienteState,
                        [e.target.name]: e.target.value,
                      });
                    }
                  });
                }
              }, 500);
            } else {
              actualizarSessionStorage(
                "Cliente",
                JSON.stringify({
                  ...clienteState,
                  [e.target.name]: e.target.value,
                })
              );
              modificarClienteState({
                ...clienteState,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            actualizarSessionStorage(
              "Cliente",
              JSON.stringify({
                ...clienteState,
                [e.target.name]: e.target.value,
              })
            );
            modificarClienteState({
              ...clienteState,
              [e.target.name]: e.target.value,
            });
          }
        }
      } else if (e.target.name === "Nombre" || e.target.name === "Apellido") {
        if (
          tiene_caracter_especial(e.target.value) ||
          e.target.value.indexOf("  ", 0) !== -1 ||
          e.target.value[0] === " " ||
          e.target.value[e.target.value.length - 1] === " "
        ) {
          elemento.setAttribute(
            "title",
            "Este campo no puede contener caracteres especiales, empezar o terminar con 1 espacio, o tener 2 espacios entre palabras"
          );
          elemento.classList.add("error");
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else {
          elemento.classList.remove("error");
          elemento.classList.add("correcto");
          elemento.setAttribute("title", "");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: capitalizarPalabras(e.target.value),
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: capitalizarPalabras(e.target.value),
          });
        }
      } else if (e.target.name === "Correo") {
        if (e.target.value.indexOf("@") < 0) {
          elemento.setAttribute("title", "El Correo debe contener una @");
          elemento.classList.add("error");
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else if (
          e.target.value.indexOf(".com", e.target.value.indexOf("@")) < 0 &&
          e.target.value.indexOf(".cc", e.target.value.indexOf("@")) < 0 &&
          e.target.value.indexOf(".co", e.target.value.indexOf("@")) < 0 &&
          e.target.value.indexOf(".es", e.target.value.indexOf("@")) < 0
        ) {
          elemento.setAttribute(
            "title",
            'El correo debe contener ".com", ".co" o ".es" detras de la @'
          );
          elemento.classList.add("error");
          elemento.classList.remove("correcto");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: "",
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: "",
          });
        } else {
          elemento.classList.remove("error");
          elemento.classList.add("correcto");
          elemento.setAttribute("title", "");
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: e.target.value,
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: e.target.value,
          });
        }
      } 
      // else if (e.target.name === "FechaNacimiento") {
      //   var fecha = e.target.value.split("-");
      //   var hoy = new Date().getFullYear();
      //   var diferencia = hoy - fecha[0];
      //   if (diferencia > 90 || diferencia < 10) {
      //     elemento.setAttribute("title", "La fecha ingresada es incorrecta");
      //     elemento.classList.add("errorFecha");
      //     elemento.classList.remove("correctoFecha");
      //     actualizarSessionStorage(
      //       "Cliente",
      //       JSON.stringify({
      //         ...clienteState,
      //         [e.target.name]: "",
      //       })
      //     );
      //     modificarClienteState({
      //       ...clienteState,
      //       [e.target.name]: "",
      //     });
      //   } else {
      //     elemento.classList.remove("errorFecha");
      //     elemento.classList.add("correctoFecha");
      //     elemento.setAttribute("title", "");
      //     let fechaReal = convertDateFormat(e.target.value);
      //     actualizarSessionStorage(
      //       "Cliente",
      //       JSON.stringify({
      //         ...clienteState,
      //         [e.target.name]: fechaReal,
      //       })
      //     );
      //     modificarClienteState({
      //       ...clienteState,
      //       [e.target.name]: fechaReal,
      //     });
      //   }
      // } 
      else if (e.target.name === "Pais") {
        insertarDepartamentos(e.target.value, function (respuesta) {
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: e.target.value,
            })
          );
          modificarClienteState({
            ...clienteState,
            [e.target.name]: e.target.value,
          });
        });
      } else if (e.target.name === "Departamento") {
        insertarCiudades(e.target.value, function (respuesta) {
          actualizarSessionStorage(
            "Cliente",
            JSON.stringify({
              ...clienteState,
              [e.target.name]: e.target.value,
            })
          );

          modificarClienteState({
            ...clienteState,
            [e.target.name]: e.target.value,
          });
        });
      } else {
        elemento.classList.remove("error");
        elemento.classList.add("correcto");
        elemento.setAttribute("title", "");
        actualizarSessionStorage(
          "Cliente",
          JSON.stringify({
            ...clienteState,
            [e.target.name]: e.target.value,
          })
        );
        modificarClienteState({
          ...clienteState,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  /*
    const limpiarDireccionState = () => {
        modificarDireccionState({via: "",Nro: "",Letra: "", Sector: "", Cruce: "", NroPlaca: "", LetraPlaca: "", NroPlaca2: "" })
        modificarClienteState({...clienteState, "Direccion" : ""})
        estadoInputsDireccion(false);
    }*/

  /*
    const actualizarDireccion = (e) => {
        if(e.target.name === "Recoger"){
            modificarClienteState({...clienteState, "Direccion" : (e.target.checked) ? "Recoger en la oficina principal" : ""}) 
            if(!e.target.checked)
                estadoInputsDireccion(false);
        }else{
            modificarDireccionState({...direccionState, [e.target.name] : e.target.value})
            modificarClienteState({...clienteState, "Direccion" : retornarDireccionCompleta(e)})
        }
    }*/

  /*
    const retornarDireccionCompleta = (e) => {
        var direccionCompleta = "";
        for(var direc in direccionState){
            if(direc === e.target.name)
                direccionCompleta += ((direc === "NroPlaca" && direccionState[direc] !== "") ? "#" : ((direc === "NroPlaca2" && direccionState[direc] !== "") ? "- " : "")) + e.target.value + " ";
            else
                direccionCompleta += ((direc === "NroPlaca" && direccionState[direc] !== "") ? "#" : ((direc === "NroPlaca2" && direccionState[direc] !== "") ? "- " : "")) + direccionState[direc] + " ";
        }
        return direccionCompleta;
    }*/

  //Bloquea la escritura de caracteres especiales.
  const bloqueoCaracteresEspeciales = (e) => {
    var elemento = document.getElementById(e.target.id);
    var texto = e.target.value;
    if (tiene_caracter_especial(e.key) && e.key !== " ") {
      elemento.value = texto.replace(e.key, "");
    }
    validacion(e);
  };

  //Bloquea la escritura de letras.
  const bloqueoLetras = (e) => {
    var elemento = document.getElementById(e.target.id);
    var texto = e.target.value;
    if (todoNumeros(e.key) && e.key !== " ") {
      elemento.value = texto.replace(e.key, "");
    }
    validacion(e);
  };

  const estadoInputsDireccion = (estado) => {
    var ViaInicio = document.getElementById("ViaInicio");
    var NumeroVia = document.getElementById("NumeroVia");
    var LetraVia = document.getElementById("LetraVia");
    var SectorDireccion = document.getElementById("SectorDireccion");
    var CruceDireccion = document.getElementById("CruceDireccion");
    var NumeroPlaca1 = document.getElementById("NumeroPlaca1");
    var NumeroPlaca2 = document.getElementById("NumeroPlaca2");
    var LetraPlaca = document.getElementById("LetraPlaca");
    ViaInicio.disabled = estado;
    NumeroVia.disabled = estado;
    LetraVia.disabled = estado;
    SectorDireccion.disabled = estado;
    CruceDireccion.disabled = estado;
    NumeroPlaca1.disabled = estado;
    NumeroPlaca2.disabled = estado;
    LetraPlaca.disabled = estado;
  };

  //Funcion que se ejecuta cada vez que se inicia este componente.
  //Pregunta si la barra de busqueda esta disponible y si es asi, la oculta y actualiza el state del Header.
  //Pregunta si la accion de Recuperar es true y la pagina es 2, actualiza y rellena los datos del usuario en el formulario.
  useEffect(() => {
    var header = JSON.parse(JSON.stringify(headerState));
    if (header["displayBuscar"] !== "none") {
      header["displayBuscar"] = "none";
      header["displayFlecha"] = "none";
      modificarHeaderState(header);
    }
    // if (clienteState["Accion"] === "Recuperar" && pedidoState["Pagina"] === 2)
    //   llenarInfo(clienteState, false, false);
    // if (clienteState["Direccion"] === "Recoger en la oficina principal") {
    //   estadoInputsDireccion(true);
    // }
  });

  return (
    <>
    {false? (
    <div className="formulariosCampo" style={{ marginBottom: "5rem" }}>
      <div className="formularioDerecha">
        <form action="#" method="post" className="form-info">
          <div className="numeroDocumento formulario">
            <label htmlFor="numeroDocumento" style={{ fontSize: "17px" }}>
              (*) Numero de documento de identidad
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Ingrese su numero de documento"
              name="NumeroDocumento"
              onKeyUp={(e) => bloqueoLetras(e)}
              id="numeroD_input"
              required
            ></input>
          </div>
          <div className="tipoDocumento formulario">
            <label htmlFor="tipoDocumento" style={{ fontSize: "17px" }}>
              (*) Tipo de documento de identificación
            </label>
            <select
              className="fontSelect"
              placeholder="Cedula de ciudadania"
              name="TipoDocumento"
              id="tipoD_input"
              onChange={(e) => validacion(e)}
              required
            >
              <option hidden defaultValue value="1">
                Seleccione su tipo de identificación
              </option>
              <option value="13">Cedula de ciudadania</option>
              <option value="12">Tarjeta de identidad</option>
              <option value="11">NIT</option>
            </select>
          </div>
          <div className="nombres formulario">
            <label htmlFor="nombres" style={{ fontSize: "17px" }}>
              (*) Nombre(s)
            </label>
            <input
              type="text"
              placeholder="Ingrese su nombre"
              name="Nombres"
              id="nombres_input"
              className="capitalizado"
              onKeyUp={(e) => bloqueoCaracteresEspeciales(e)}
              required
            ></input>
          </div>
          <div className="apellidos formulario">
            <label htmlFor="apellidos" style={{ fontSize: "17px" }}>
              (*) Apellido(s)
            </label>
            <input
              type="text"
              placeholder="Ingrese su apellido"
              name="Apellidos"
              className="capitalizado"
              onKeyUp={(e) => bloqueoCaracteresEspeciales(e)}
              id="apellidos_input"
              required
              disabled={clienteState.TipoDocumento=="11"}
            ></input>
          </div>
          <div className="celular formulario">
            <label htmlFor="celular">(*) Numero de celular</label>
            <input
              type="text"
              placeholder="Ingrese su numero de celular"
              name="Celular"
              onKeyUp={(e) => bloqueoLetras(e)}
              id="celular_input"
              required
            ></input>
          </div>
          {/* <div className="fecha formulario">
            <label htmlFor="fecha" style={{ fontSize: "17px" }}>
              (*) Fecha de nacimiento
            </label>
            <input
              type="date"
              style={{ fontSize: "15px", marginBottom: "10px" }}
              placeholder="Ingrese su fecha de nacimiento"
              name="FechaNacimiento"
              id="fechaNacimiento_input"
              onChange={(e) => validacion(e)}
              min="1910-01-01"
              required
            ></input>
          </div> */}
          {/* {path.pathname === "/Concursonacionaldediseno" ? (
            <div className="universidad formulario">
            <label htmlFor="universidad" style={{ fontSize: "17px" }}>
              (*) Universidad
            </label>
            <input
              type="text"
              placeholder="Ingrese su universidad"
              name="Universidad"
              onKeyUp={(e) => bloqueoCaracteresEspeciales(e)}
              id="universidad_input"
              required
            ></input>
          </div>
          ): (
            <></>
          )} */}
          
        </form>
      </div>
      <div className="formularioIzquierda">
        <form action="#" method="post" className="form-info">
          <div className="pais formulario">
            <label htmlFor="pais" style={{ fontSize: "17px" }}>
              (*) País
            </label>
            <select
              className="fontSelect"
              placeholder="Pais"
              name="Pais"
              id="pais_input"
              onChange={(e) => validacion(e)}
              required
            >
              <option hidden defaultValue>
                Seleccione su país
              </option>
              <option value="60c8c846e76f272f6835f153">Colombia</option>
            </select>
          </div>
          <div className="departamento formulario">
            <label htmlFor="departamento">(*) Departamento</label>
            <select
              className="fontSelect"
              placeholder="Departamento"
              name="Departamento"
              id="departamento_input"
              onChange={(e) => validacion(e)}
              required
            >
              <option hidden defaultValue>
                Seleccione su departamento
              </option>
            </select>
          </div>
          <div className="ciudad formulario">
            <label htmlFor="ciudad" style={{ fontSize: "17px" }}>
              (*) Ciudad
            </label>
            <select
              className="fontSelect"
              placeholder="Ciudad"
              name="Ciudad"
              id="ciudad_input"
              onChange={(e) => validacion(e)}
              required
            >
              <option hidden defaultValue>
                Seleccione su ciudad
              </option>
            </select>
          </div>
          <div className="email formulario">
            <label htmlFor="email" style={{ fontSize: "17px" }}>
              (*) Correo electrónico
            </label>
            <input
              type="email"
              placeholder="Ingrese su correo electrónico"
              name="Correo"
              id="correo_input"
              onChange={(e) => validacion(e)}
              required
            ></input>
          </div>
          <div className="genero formulario">
            <label htmlFor="genero">(*) Genero</label>
            <select
              className="fontSelect"
              placeholder="genero"
              name="Genero"
              id="genero_input"
              onChange={(e) => validacion(e)}
              required
            >
              <option hidden defaultValue>
                Seleccione su genero
              </option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </select>
          </div>
          <div className="row">
            <div
              className="direccion formularioMitad"
              style={{ fontSize: "17px" }}
            >
              <label htmlFor="direccion">(*) Dirección</label>
              <input
                type="text"
                placeholder="Ingrese su dirección"
                name="Direccion"
                onChange={(e) => validacion(e)}
                id="direccion_input"
                required
              ></input>
            </div>
            <div
              className="barrio formularioMitad"
              style={{ fontSize: "17px" }}
            >
              <label htmlFor="barrio">(*) Barrio</label>
              <input
                type="text"
                placeholder="Ingrese su barrio"
                name="Barrio"
                onChange={(e) => validacion(e)}
                id="barrio_input"
                required
              ></input>
            </div>
          </div>
          <label style={{ fontSize: "13px" }}>
            <input
              type={"checkbox"}
              style={{ marginBottom: "0px" }}
              value="EnvioMensaje"
              defaultChecked
              onChange={() => console.log("desactivado")}
            ></input>{" "}
            Autorizo el envió de publicidad y ofertas a través de correo y
            mensajería instantánea.
          </label>
        </form>
      </div>
    </div>
    ) : null}
    <FormRegistro
      apiUrlState={apiUrlState}
      headerState={headerState}
      actualizarSessionStorage={actualizarSessionStorage}
      spinnerState={spinnerState}
      modificarSpinnerState={modificarSpinnerState}
      actualizar={actualizar}
      setActualizar={setActualizar}
      vendedor={vendedor}
    />
    </>
  );
};

export default Registro;
