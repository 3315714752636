import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import SidebarData from './Sidebar';
import './static/Navbar.css';
import { IconContext } from 'react-icons';

function NavBar({setView}) {
  const menu = !setView;
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          {menu? (
            <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
          ): (
            <></>
          )}
          
          <img
            src="https://hosting.dkda.com.co/AppWeb/img/Header/logos/logo-blanco.webp"
            alt="Croatta"
            className='croatta'/>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  {
                    item.title === 'Catalogos' ?
                     <a href={item.path}> 
                      {item.icon}
                      <span>{item.title}</span>
                     </a> : 
                    <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                    
                    }
                  
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default NavBar;