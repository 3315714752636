import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const NotFoundComponent = ({ redirectTo }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <FaExclamationCircle size={50} className="logo" />
      <h2>No se encontraron productos para estas características</h2>
      <p>
        Lo sentimos, intenta aplicar un filtro diferente o da click en el
        siguiente boton y sigue comprando.
      </p>
        {/* <a  href={redirectTo}>
          {" "}
          <p
            className="botonLimpiarFiltros text-center FuenteBebas"
            style={{ cursor: "pointer" }}
          >
            Volver
          </p>
        </a> */}
    </div>
  );
};

export default NotFoundComponent;
