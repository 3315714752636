//Zona de importacion de componentes externos.
import React from "react";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

const Carousel = ({
  carousel,
  toCarouselUp,
  toCarouselDown,
  headerState,
  modificarHeaderState,
  recursosUrlState,
  actualizarSessionStorage,
}) => {
  //Se extraen los atributos del state carousel.
  const { id, srcImagen, displayArriba, displayAbajo } = carousel;

  //Se obtiene el color en el que debe estar el header actual.
  const { color } = headerState;

  //Se definen las variables para los colores de botones y textos del carousel segun la constante de color actual.
  let colorActual;
  let imagenFlechaArriba;
  let imagenFlechaAbajo;
  let imagenFlechaIzquierda;
  let imagenFlechaDerecha;

  //Segun la constante del color se definen que recursos se utilizaran diferenciandose solamente en color (blanco o negro) de la imagen.
  if (color === "blanco") {
    colorActual = "rgb(255, 255, 255)";
    imagenFlechaArriba =
      recursosUrlState + "/img/Index/web/botones/flecha-arriba-blanco.webp";
    imagenFlechaAbajo =
      recursosUrlState + "/img/Index/web/botones/flecha-abajo-blanco.webp";
    imagenFlechaIzquierda =
      recursosUrlState + "/img/Index/web/botones/flecha-izquierda-blanco.webp";
    imagenFlechaDerecha =
      recursosUrlState + "/img/Index/web/botones/flecha-derecha-blanco.webp";
  } else {
    colorActual = "rgb(0, 0, 0)";
    imagenFlechaArriba =
      recursosUrlState + "/img/Index/web/botones/flecha-arriba-negro.webp";
    imagenFlechaAbajo =
      recursosUrlState + "/img/Index/web/botones/flecha-abajo-negro.webp";
    imagenFlechaIzquierda =
      recursosUrlState + "/img/Index/web/botones/flecha-izquierda-negro.webp";
    imagenFlechaDerecha =
      recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp";
  }

  //Propiedades de las flechas de arriba y abajo del carousel, el display se define mediante la proiedad traida en el state carousel.
  const flechaAbajo = {
    height: 15,
    width: 26,
    display: displayAbajo,
    cursor: "pointer",
  };

  const flechaArriba = {
    height: 15,
    width: 26,
    display: displayArriba,
    cursor: "pointer",
  };

  //Mediante el parametro url se determina la url de respuesta sustituyendo  el 'webp' por el 'png'.
  /*
    const cambioPng = (url) => {
        return url.replace(/webp/g,"png")
    }*/

  //Esta funcion cambia el mercado al que se dirigira o esta actualmente, entre minoristas o mayoristas. Tambien modificando la sessionStorage de este.
  //Con el objetivo que tipo de mercado mostrarle al cliente en todo momento.
  //Finalmente lleva a la ruta de Mercado.
  const CambioProductos = (mercado) => {
    actualizarSessionStorage("TipoCliente", mercado);
    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.png",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });
    if (mercado === "Mayoristas") {
      TagManager.dataLayer({
        dataLayer: {
          event: "Carrito",
          path: "/Mayoristas",
        },
      });
      //ReactPixel.pageView();
      ReactPixel.track("BotonMayoristas", "ViewContent");
    }
    if (mercado === "Minoristas") {
      TagManager.dataLayer({
        dataLayer: {
          event: "Carrito",
          path: "/Minoristas",
        },
      });
      ReactPixel.track("BotonMinoristas", "ViewContent");
    }
    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });
  };

  //Mediante el parametro url se determina la url de respuesta sustituyendo  el 'webp' por el 'png'.
  const cambioPng = (url) => {
    return url.replace(/webp/g, "png");
  };

  const textoBoton = () => {
    switch (id) {
      case 1:
        return "Nuevo";
      case 2:
        return "Superior";
      case 3:
        return "Inferior";
      case 4:
        return "Accesorios";
      default:
        return "Ñunga";
    }
  };

  /* La estructura del carousel es la siguiente:
        -Carousel 1, 2, 3, 4-
        Imagen central (banner)
        Boton central
        Flechas de mayorista o minorista (Version Web) / Botones de mayorista o minorista (Version Movil)

        -Carousel 5-
        Visualizacion de botones de redes sociales 
    */
  return (
    <div className="snap">
      {id < 5 ? (
        <div>
          <Link to="/Mercado" onClick={() => CambioProductos("Minoristas")}>
            <div className="BotonCentralWeb">
              <button id="botonCentral" className="SinDecoracion FuenteBebas">
                {textoBoton()}
              </button>
            </div>
          </Link>
          <Link to="/Mercado" onClick={() => CambioProductos("Minoristas")}>
            <div className="BotonCentralMovil">
              <button id="botonCentral" className="SinDecoracion FuenteBebas">
                {textoBoton()}
              </button>
            </div>
          </Link>
          <img
            className="SinDecoracion"
            id="botonArriba"
            src={imagenFlechaArriba}
            style={flechaArriba}
            onClick={toCarouselUp}
            alt="flechaArriba"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(imagenFlechaArriba);
            }}
          />
          <img
            className="SinDecoracion"
            id="botonAbajo"
            src={imagenFlechaAbajo}
            style={flechaAbajo}
            onClick={toCarouselDown}
            alt="flechaAbajo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(imagenFlechaAbajo);
            }}
          />
          <div id="MercadoWeb">
            <img
              src={srcImagen + "Web.webp"}
              id="imagenCarousel"
              alt="ImagenCarousel"
              draggable="false"
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = srcImagen + "Web.png";
              }}
            />
            <Link to="/Mercado" onClick={() => CambioProductos("Minoristas")}>
              <div id="ZonaIzquierda">
                <p
                  className="FuenteBebas"
                  id="Minoristas"
                  style={{ color: colorActual }}
                >
                  MINORISTAS
                </p>
                <img
                  id="botonIzquierda"
                  src={imagenFlechaIzquierda}
                  alt="flechaIzquierda"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = cambioPng(imagenFlechaIzquierda);
                  }}
                />
              </div>
            </Link>
            <Link
              to="/Mayoristas"
              onClick={() => CambioProductos("Mayoristas")}
            >
              <div id="ZonaDerecha">
                <img
                  id="botonDerecha"
                  src={imagenFlechaDerecha}
                  alt="flechaDerecha"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = cambioPng(imagenFlechaDerecha);
                  }}
                />
                <p
                  className="FuenteBebas"
                  id="Mayoristas"
                  style={{ color: colorActual }}
                >
                  MAYORISTAS
                </p>
              </div>
            </Link>
          </div>

          <div id="MercadoMovil" style={{ color: colorActual }}>
            <img
              src={srcImagen + "Movil.webp"}
              id="imagenCarousel"
              alt="ImagenCarousel"
              draggable="false"
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = srcImagen + "Movil.png";
              }}
            />
            <Link to="/Mercado">
              <p
                className="FuenteBebas marcasIndex"
                id="Minoristas"
                style={{ color: colorActual, cursor: "pointer" }}
                onClick={() => CambioProductos("Minoristas")}
              >
                MINORISTAS
              </p>
            </Link>
            <Link to="/Mayoristas">
              <p
                className="FuenteBebas marcasIndex"
                id="Mayoristas"
                style={{ color: colorActual, cursor: "pointer" }}
                onClick={() => CambioProductos("Mayoristas")}
              >
                MAYORISTAS
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div id="MercadoWeb">
            <img
              src={srcImagen + "Web.webp"}
              id="imagenCarousel"
              alt="ImagenCarousel"
              draggable="false"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = srcImagen + "Web.png";
              }}
            />
          </div>

          <div id="MercadoMovil">
            <img
              src={srcImagen + "Movil.webp"}
              id="imagenCarousel"
              alt="ImagenCarousel"
              draggable="false"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = srcImagen + "Movil.png";
              }}
            />
          </div>
          <img
            className="SinDecoracion"
            id="botonArriba"
            src={imagenFlechaArriba}
            style={flechaArriba}
            onClick={toCarouselUp}
            alt="flechaArriba"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(imagenFlechaArriba);
            }}
          ></img>
          <img
            className="SinDecoracion"
            id="botonAbajo"
            src={imagenFlechaAbajo}
            style={flechaAbajo}
            onClick={toCarouselDown}
            alt="flechaAbajo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = cambioPng(imagenFlechaAbajo);
            }}
          ></img>
          <p className="FuenteBebas" id="Buscanos">
            BÚSCANOS EN REDES
          </p>
          <div id="ZonaCentral">
            <a
              href="https://www.facebook.com/dkda.oficial?mibextid=JRoKGi"
              id="BotonesRedes"
              target="noreferrer"
            >
              <img
                src={recursosUrlState + "/img/Index/web/botones/fb.webp"}
                alt="Facebook"
                style={{ width: "54px", height: "54px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = cambioPng(
                    recursosUrlState + "/img/Index/web/botones/fb.png"
                  );
                }}
              ></img>
            </a>
            <a
              href="https://www.instagram.com/dk.tiendaoficial?igsh=MTF6dmFsNmtidDFwYQ%3D%3D&utm_source=qr"
              id="BotonesRedes"
              target="noreferrer"
            >
              <img
                src={recursosUrlState + "/img/Index/web/botones/ins.webp"}
                alt="Instagram"
                style={{ width: "54px", height: "54px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = cambioPng(
                    recursosUrlState + "/img/Index/web/botones/ins.png"
                  );
                }}
              ></img>
            </a>
            <a
              href="https://co.pinterest.com/croatta/"
              id="BotonesRedes"
              target="noreferrer"
            >
              <img
                src={recursosUrlState + "/img/Index/web/botones/pint.webp"}
                alt="Pinterest"
                style={{ width: "54px", height: "54px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = cambioPng(
                    recursosUrlState + "/img/Index/web/botones/pint.png"
                  );
                }}
              ></img>
            </a>
            <a
              href="https://wa.link/nhyxt9"
              id="BotonesRedes"
              target="noreferrer"
            >
              <img
                src={recursosUrlState + "/img/Index/web/botones/wpp.webp"}
                alt="Whatsapp"
                style={{ width: "54px", height: "54px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = cambioPng(
                    recursosUrlState + "/img/Index/web/botones/wpp.png"
                  );
                }}
              ></img>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
